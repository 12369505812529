import buildingIcon from 'assets/icons/building.svg';
import globIcon from 'assets/icons/glob.svg';
import certIcon from 'assets/icons/cert.svg';

const getIcon = name => {
  switch (name) {
    case 'cert':
      return certIcon;
    case 'glob':
      return globIcon;
    case 'building':
      return buildingIcon;
    default:
      return null;
  }
};

export default getIcon;
