import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import LazyImage from 'components/shared/LazyImage';
import Content from 'components/shared/Content';
import useSlider from 'hooks/useSlider';
import Logo from 'components/shared/Logo';
import { LangContext } from 'contexts/LangContext';
import LangLink from 'components/shared/LangLink';
import { HeadingOne } from 'components/shared/Headings';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import scrollToSection from 'utils/scrollToSection';
import SocialMedia from 'components/Contents/SocialMedia';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 580px;
  ${({ theme }) => theme.mq.md} {
    min-height: 750px;
  }
  ${({ theme }) => theme.mq.xxl} {
    min-height: 850px;
  }
`;

const Topbar = styled.nav`
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 10;
  ${({ theme }) => theme.mq.lg} {
    top: 60px;
  }
  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

const LogoWrapper = styled.div`
  width: 165px;
  ${({ theme }) => theme.mq.s} {
    width: 200px;
  }
`;

const LangOptions = styled.div`
  display: flex;
  flex-direction: column-reverse;
  font-weight: 400;
  ${({ theme }) => theme.mq.s} {
    font-size: 1.8rem;
    font-weight: 300;
    flex-direction: row;
    justify-content: center;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
  }
`;

const LangSpacer = styled.span`
  display: block;
  margin: 0 8px;
`;

const LangBtn = styled(LangLink)`
  color: #fff;
  text-decoration: none;
  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
    `}
`;

const SlidesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const SlidesInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: radial-gradient(
      ellipse closest-side,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 60%,
      rgba(0, 0, 0, 0.5) 170%
    );
  }
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease-in-out;
  ${({ isActive }) =>
    isActive &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

const BottomText = styled.div`
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  z-index: 10;
  @media (min-height: 620px) {
    bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    bottom: 90px;
  }
  ${({ theme }) => theme.mq.xxl} {
    bottom: 30px;
  }
`;

const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.4;
  margin: 0 auto;
  max-width: 360px;
  ${({ theme }) => theme.mq.md} {
    font-size: 2.1rem;
    max-width: 420px;
    margin: 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 3rem;
    max-width: 560px;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (min-height: 620px) {
    margin-top: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    max-width: 420px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 560px;
  }
`;

const ArrowButton = styled.button`
  background: transparent;
  pointer-events: all;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-50%);
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 270px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md} {
    margin: 0 0 0 15px;
    max-width: 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 530px;
  }
  ${HeadingOne} {
    font-weight: 100;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => theme.mq.s} {
    justify-content: center;
    align-items: center;
  }
`;

const HomeHeader = ({ images, title, text }) => {
  const { activeSlide } = useSlider(images);
  const { lang } = useContext(LangContext);
  return (
    <Wrapper>
      <Topbar>
        <Content>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <LangOptions>
            <SocialMedia />
            <Flex>
              <LangBtn langButton="en" active={lang === 'en'}>
                ENG
              </LangBtn>
              <LangSpacer>|</LangSpacer>
              <LangBtn langButton="pl" active={lang === 'pl'}>
                PL
              </LangBtn>
            </Flex>
          </LangOptions>
        </Content>
      </Topbar>
      <TitleWrapper>
        <Content>
          <TitleContent>
            <HeadingOne dangerouslySetInnerHTML={{ __html: title }} />
          </TitleContent>
        </Content>
      </TitleWrapper>
      <BottomText>
        <Content>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
          <ArrowWrapper>
            <ArrowButton
              onClick={() => scrollToSection('#videos')}
              aria-label="scroll down"
            >
              <ArrowDownIcon />
            </ArrowButton>
          </ArrowWrapper>
        </Content>
      </BottomText>
      <SlidesWrapper>
        <SlidesInnerWrapper>
          {images.map(({ src, alt }, index) => (
            <Slide key={index}>
              <ImageWrapper isActive={activeSlide === index}>
                <LazyImage
                  src={src}
                  alt={alt || `hero image ${index + 1}`}
                  cover
                />
              </ImageWrapper>
            </Slide>
          ))}
        </SlidesInnerWrapper>
      </SlidesWrapper>
    </Wrapper>
  );
};

HomeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HomeHeader;
