import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import { HeadingTwo } from 'components/shared/Headings';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyImage from 'components/shared/LazyImage';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-small.svg';

SwiperCore.use([Autoplay]);

const StyledSection = styled(Section)`
  ${({ shortMobile }) =>
    shortMobile &&
    css`
      @media (max-width: 575px) {
        padding-top: 0 !important;
        padding-bottom: 15px !important;
      }
    `}
`;

const InnerWrapper = styled.div`
  cursor: grab;
  width: 100%;
  height: 100%;
  clip-path: inset(-100vw -100vw -100vw 0);
  padding-top: 20px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  .swiper-slide {
    display: flex;
    align-items: center;
    width: auto !important;
    height: auto !important;
    max-width: max-content;
  }
  .swiper-container {
    height: 100%;
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-scrollbar {
    display: none !important;
  }
`;

const PartnerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  ${({ theme }) => theme.mq.xxl} {
    margin: 0 15px;
  }
  & > div {
    width: ${({ width }) => `${width / 3.85}px`} !important;
    height: ${({ height }) => `${height / 3.85}px`} !important;
    ${({ theme }) => theme.mq.xxl} {
      width: ${({ width }) => `${width / 2}px`} !important;
      height: ${({ height }) => `${height / 2}px`} !important;
    }
  }
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  ${({ theme }) => theme.mq.xl} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 1.8rem)`};
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5.6rem;
    padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 25px')};
  }
`;

const Controls = styled.div`
  display: flex;
  margin-top: 15px;
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
  ${({ theme }) => theme.mq.xxl} {
    display: block;
  }
`;

const NextBtn = styled.button`
  background: transparent;
  border: 1px solid #fff;
  padding: 2px 10px;
  margin-right: 10px;
  cursor: pointer;
  ${({ theme }) => theme.mq.md} {
    padding: 5px 15px;
  }
`;

const PrevBtn = styled(NextBtn)`
  transform: rotate(180deg);
`;

const settings = {
  loop: false,
  slidesPerView: 'auto',
};

const HorizontalCarousel = ({ title, items, shortMobile, autoplay }) => {
  const swiperRef = useRef(null);

  if (autoplay) {
    settings.autoplay = {
      delay: 2000,
      disableOnInteraction: false,
    };
    settings.speed = 600;
    settings.loop = true;
  }

  const goNext = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <StyledSection shortMobile={shortMobile}>
      <Content>
        <StyledHeadingTwo dangerouslySetInnerHTML={{ __html: title }} />
        {items && (
          <InnerWrapper disabled={!autoplay}>
            <Swiper ref={swiperRef} {...settings}>
              {items.map(({ img, link }, index) => (
                <SwiperSlide key={index}>
                  <PartnerItem
                    as={link ? 'a' : 'div'}
                    href={link}
                    target="_blank"
                    width={img.width}
                    height={img.height}
                  >
                    <LazyImage src={img} alt={`image ${index + 1}`} fixed />
                  </PartnerItem>
                </SwiperSlide>
              ))}
            </Swiper>
          </InnerWrapper>
        )}
        {!autoplay && (
          <Controls>
            <PrevBtn onClick={goPrev}>
              <ArrowIcon />
            </PrevBtn>
            <NextBtn onClick={goNext}>
              <ArrowIcon />
            </NextBtn>
          </Controls>
        )}
      </Content>
    </StyledSection>
  );
};

HorizontalCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  shortMobile: PropTypes.bool,
  autoplay: PropTypes.bool,
};

HorizontalCarousel.defaultProps = {
  shortMobile: false,
  autoplay: false,
};

export default HorizontalCarousel;
