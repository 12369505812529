import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Logo from 'components/shared/Logo';
import Icon from 'components/shared/Icon';
import getIcon from 'utils/getIcon';
import { LangContext } from 'contexts/LangContext';

const Wrapper = styled(Section)`
  position: relative;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin: 0 0 25px 0;
  }
  &:first-child {
    margin: 0 0 30px 0;
    div {
      width: auto;
    }
  }
  ${({ theme }) => theme.mq.xl} {
    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  }
  a {
    &:hover {
      color: #fff !important;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.3s;
  max-width: max-content;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.4);
  b {
    font-size: 2.2rem;
    color: rgba(255, 255, 255, 0.5);
  }
  a {
    color: inherit;
    text-decoration: none;
    transition: 0.2s;
  }
  ${({ theme }) => theme.mq.xl} {
    font-weight: 400;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 1.8rem;
  }
`;

const IconWrapper = styled.div`
  margin-right: 25px;
  padding-top: 5px;
  @media (max-width: 1199px) {
    div {
      width: 40px !important;
      height: 40px !important;
    }
  }
`;

const LogoWrapper = styled(ItemWrapper)`
  height: 38px;
`;

const Footer = ({ elements }) => {
  const { translate } = useContext(LangContext);

  return (
    <Wrapper as="footer">
      <Content>
        <InnerWrapper>
          <LogoWrapper>
            <Logo to={translate('investKomfortURL')} />
          </LogoWrapper>
          {elements.map(({ highlight, text, icon }) => (
            <ItemWrapper key={highlight}>
              {icon && (
                <IconWrapper>
                  <Icon src={getIcon(icon)} alt={icon} auto />
                </IconWrapper>
              )}
              <Text>
                <b>{highlight}</b>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </Text>
            </ItemWrapper>
          ))}
        </InnerWrapper>
      </Content>
    </Wrapper>
  );
};

Footer.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Footer;
