import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import { HeadingTwo } from 'components/shared/Headings';
import Player from 'components/Player/Player';

const PlayerWrapper = styled.div`
  ${({ theme }) => theme.mq.xxl} {
    padding: 20px 0 0;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0 10px;
  ${({ theme }) => theme.mq.lg} {
    padding: 32px 0 10px;
  }
`;

const VideoTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.4;
  ${({ theme }) => theme.mq.md} {
    font-size: 2.2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 3rem;
  }
  br {
    display: none;
  }
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  display: block;
  color: inherit;
  text-decoration: none;
  ${({ theme }) => theme.mq.xl} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 1.8rem)`};
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5.6rem;
    padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 25px')};
  }
`;

const Time = styled(VideoTitle)`
  color: #ccc;
  font-weight: 300;
`;

const HeadingWrapper = styled.div`
  h2 {
    text-align: center;
  }
`;

const PromotedVideo = ({ heading, title, titleURL, video }) => {
  return (
    <Section noBottom>
      <Content id="promoted-video">
        {heading && (
          <HeadingWrapper>
            <StyledHeadingTwo dangerouslySetInnerHTML={{ __html: heading }} />
          </HeadingWrapper>
        )}
        <StyledHeadingTwo
          as={Link}
          to={titleURL}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <PlayerWrapper>
          <Player video={video} />
          {/* <PlayerInfo>
            <VideoTitle
              dangerouslySetInnerHTML={{
                __html: `${video.number}. ${video.title}`,
              }}
            />
            <Time as="time">{video.duration}</Time>
          </PlayerInfo> */}
        </PlayerWrapper>
      </Content>
    </Section>
  );
};

PromotedVideo.propTypes = {
  heading: PropTypes.string.isRequired,
  titleURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PromotedVideo;
